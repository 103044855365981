<template>
	<div class="bg-mafia-dark flex-column position-relative">
		<div class="flex-1 flex-column justify-center">
			<div><img :src="require('@/assets/image/web/ico_logo_large.svg')" /></div>
			<div class="mt-20 mafia">M.A.F.I.A</div>
			<div class="mt-20">문화예술 전용 커뮤니티 마피아 프로젝트</div>
		</div>

		<div class="intro_btn_wrap pa-30">
			<div class="social_login">

				<button
					class="btn btn-kakao"
					@click="loginWith('kakao')"
				>Kakao 로그인</button>

				<button
					class="btn btn-google mt-20"
					@click="loginWith('google')"
					:disabled="!isInit"
				>Google 로그인</button>

				<button
					class="btn btn-apple mt-20"
					@click="loginWith('apple')"
				>Apple 로그인</button>

				<vue-apple-login
					v-show="false"

					:onSuccess="handleAppleLoginSuccess"
					:onFailure="handleAppleLoginFail"
				></vue-apple-login>
			</div>

			<button
				@click="$emit('to', { name: 'mafia004'})"
				class="color-white mt-20"
			>회원가입</button>

			<p
				class="copyright mt-20 color-gray"
			>Copyright © MAFI Inc. </p>
		</div>
	</div>
</template>

<script>

export default {
	name: 'mafia011'
	, props: ['user']
	, components: { }
	,data: function(){
		return {
			program: {
				name: '로그인'
				, not_header: true
				, not_footer: true
				, type: 'auth'
			}
			,kakao_info: null
			,isInit: false
			,isSignIn: false
			, social_code: ''
			, state: '1234'
			,is_pin: false
			,pin_option: {
				pin_type: 'check'
			}
			, is_otp: false
			, is_auto: this.$route.params.auto
		}
	}
	, computed: {
		kakao_return_url: function(){

			let domain = process.env.VUE_APP_DOMAIN
			let dev = process.env.VUE_APP_DEV
			let local = 'https://localhost:8080'
			let location = window.location.href
			let url = ''

			console.log('location', location)
			if(location.indexOf(domain) > -1){
				url = domain
			}else if(location.indexOf(dev) > -1){
				url = dev
			}else{
				url = local
			}

			return url + '/auth/login/kakao'
		}
	}
	,methods: {
		loginWith: async function(type){
			console.log('loginWith')
			if(type == 'kakao'){
				this.social_code = 'MB00400001'
				try{
					await this.handleSignInKakao()
				}catch(e){
					console.log('kakao login error', e)
				}

			}else if(type == 'google'){
				this.social_code = 'MB00400003'
				try{
					await this.handleClickUpdateScope2()
				}catch(e){
					console.log('google login error', e)
				}
			}else if(type == 'apple'){
				this.social_code = 'MB00400002'
				// next
				try{
					document.getElementById('appleid-signin').click()
				}catch(e){
					console.log('apple login error', e)
				}
			}
		}
		, async handleSignInKakao() {
			if(!window.Kakao.isInitialized()){
				window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
			}
			
			let t = false
			if(t){
				let REST_API_KEY = process.env.VUE_APP_KEY_KAKAO_REST
				let REDIRECT_URI = this.kakao_return_url
				window.document.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&state=1234`
			}else{
				console.log(window.Kakao.isInitialized());
				console.log('kakao token: ' + window.Kakao.Auth.getAccessToken())
				await this.kakaoLogin()
			}
		}
		,async handleClickUpdateScope2() {
			console.log('handleClickUpdateScope2')

			const option = new window.gapi.auth2.SigninOptionsBuilder();
			option.setScope("email https://www.googleapis.com/auth/drive.file");
			const googleUser = await this.$gAuth.GoogleAuth.currentUser.get()
			let t = googleUser.getAuthResponse();

			try {
				let result = await googleUser.grant(option);
				console.log("result", result);

				if(!t.access_token){
					t = googleUser.getAuthResponse();
					console.log("t re!!", t);
				}

				let r = {
					type : 'google'
					, access_token: t.access_token
				}

				await this.loginWithSocial(r);

				//await this.joinWithGoogle(googleUser.Cc.access_token, googleUser.Av.mw)
			} catch (error) {
				console.error(error);
			}
		}

		,kakaoLogin: async function(){
			let self = this
			window.Kakao.Auth.loginForm({
				success: function() {
					/*
					this.kakao_info = authObj
					console.log(this.kakao_info, authObj)
					console.log('kakao login token', window.Kakao.Auth.getAccessToken())
					 */
					let r = {
						type: 'kakao'
						, access_token: window.Kakao.Auth.getAccessToken()
					}

					self.loginWithSocial(r)
				},
				fail: function(err) {
					console.log(err)
				},
			})
		}

		, loginWithSocial: async function(e){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_login_social
					, data: {
						member_social_certfc_code: this.social_code
						, social_certfc_code: e.access_token
					}
					, type: true
				})

				if(result.success){
					this.token_info = result.data
					console.log('this.token_info', this.token_info)

					sessionStorage.removeItem('Error')
					await this.$encodeStorage.setSessionToken(result.data.session_token)

					switch (result.data.member_state_code){
						default:
							this.$emit('to', {name: 'mafia009'})
							break;
						case 'MB00500001':
							this.$emit('to', {name: 'sms'})
							break;
						case 'MB00500002':
							this.$bus.$emit('onPin', 'join')
							break;
						case 'MB00500003':
							this.$emit('to', {name: 'mafia0101'})
							break;
						case 'MB00500004':
							this.$emit('to', {name: 'nice'})
							break;
						case 'MB00500005':
							await this.$encodeStorage.setSessionToken(result.data.session_token)
							await this.$encodeStorage.setItem('login_info', e)
							this.onPin('pin_login')
							//this.$bus.$emit('setUser', result.data)
							break;
						case 'MB00500006':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
							break;
						case 'MB00500007':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
							break;
						case 'MB00500008':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
							break;
					}
				}else{
					console.log(result)
					if((result.code).indexOf('E0014') > -1){
						await this.$encodeStorage.removeItem('login_info')
						throw '인증이 만료되었습니다. 다시 로그인하여 주세요'
					}else {

						switch (result.code) {
							case 'E000400012':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
								break;
							case 'E000400013':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
								break;
							case 'E000400014':
								this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
								break;
							case 'E001400003':
							case 'E001400004':
							case 'E001400008':
								await this.$encodeStorage.removeItem('login_info')
								throw '인증이 만료되었습니다. 다시 로그인하여 주세요'
							default:
								await this.$encodeStorage.removeItem('login_info')
								throw result.message
						}
					}
				}
			}catch (e) {
				console.log(e)
				this.error = e

				this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: e}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,async handleClickUpdateScope() {
			const option = new window.gapi.auth2.SigninOptionsBuilder();
			option.setScope("email https://www.googleapis.com/auth/drive.file");
			const googleUser = this.$gAuth.GoogleAuth.currentUser.get();
			try {
				await googleUser.grant(option);
				console.log(googleUser)
				console.log("success");
			} catch (error) {
				console.error(error);
			}
		},
		handleClickLogin() {
			this.$gAuth
				.getAuthCode()
				.then((authCode) => {
					//on success
					console.log("authCode", authCode);
				})
				.catch((error) => {
					console.log(error)
				});
		},
		async handleClickSignIn() {
			try {
				const googleUser = await this.$gAuth.signIn();
				if (!googleUser) {
					return null;
				}
				console.log("googleUser", googleUser);
				console.log("getId", googleUser.getId());
				console.log("getBasicProfile", googleUser.getBasicProfile());
				console.log("getAuthResponse", googleUser.getAuthResponse());
				console.log(
					"getAuthResponse",
					this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
				);
				this.isSignIn = this.$gAuth.isAuthorized;
			} catch (error) {
				//on fail do something
				console.error(error);
				return null;
			}
		},
		async handleClickSignOut() {
			try {
				await this.$gAuth.signOut();
				this.isSignIn = this.$gAuth.isAuthorized;
				console.log("isSignIn", this.$gAuth.isAuthorized);
			} catch (error) {
				console.error(error);
			}
		},
		handleClickDisconnect() {
			window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
		}
		, onOtp: function(){
			this.is_pin = false
			this.is_otp = true
		}
		, postOtp: async function(gogle_otp_certfc_number){
			try {
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_google_otp
					, data: {
						gogle_otp_certfc_number: gogle_otp_certfc_number
					}
					, type: true
				})

				if(result.success){
					this.onPin('update')
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, onPin: function(pin_type){
			this.$bus.$emit('onPin', pin_type)
		}
		, offPin: function(){
			this.is_pin = false
			this.pin_option.pin_type = 'check'
		}
		, handleAppleLoginSuccess: function(data){
			console.log('handleAppleLoginSuccess', data)
			this.social_code = 'MB00400002'
			let e = {
				type: 'apple'
				, access_token: data.authorization.code
			}
			this.loginWithSocial(e)
		}
		, handleAppleLoginFail: function(error){
			console.log(error)
			this.$bus.$emit('notify', { type: 'error', message: '애플 로그인 실패'})
		}
	}
	,async created() {
		// console.log('auth login user', this.user)
		let at = this.$encodeStorage.getSessionAt()
		if(at){
			await this.$router.push({ name: 'main'})
		}else{

			this.$emit('onLoad', this.program)

			let checkGauthLoad = setInterval(() => {
				this.isInit = this.$gAuth.isInit;
				this.isSignIn = this.$gAuth.isAuthorized;
				if (this.isInit) clearInterval(checkGauthLoad);
			}, 1000);

			console.log('this.is_auto', this.is_auto)
			if(this.is_auto){
				let login_info = this.$encodeStorage.getItem('login_info')
				console.log('login_info', login_info)
				if(login_info){

					let e = {
						access_token: login_info.access_token
						, type: login_info.type
					}
					switch (login_info.type){
						case 'kakao':
							this.social_code = 'MB00400001'
							break
						case 'google':
							this.social_code = 'MB00400003'
							break
						case 'apple':
							this.social_code = 'MB00400002'
							break
						default:
							return false
					}
					await this.loginWithSocial(e)

				}else{
					return false
				}
			}else{
				this.$bus.$emit('getDeviceToken')
			}

			this.$bus.$on('pinCallback', (pin_type) => {
				switch (pin_type){
					case 'login':

						break
					case 'update':
						break
					case 'join':

						this.$bus.$off('pinCallback')
						this.$emit('to', { name: 'mafia0101'})
						break
				}
			})

			window.sign_in_social = (e) => {
				try{
					console.log(e.type)
					console.log(e.access_token)
					console.log(e.email)
					this.loginWithSocial(e)
				}catch (e) {
					console.log(e)
				}
			}

			window.fail_in_social = (e) => {
				console.log(e)
				this.$encodeStorage.removeItem('login_info')
				this.is_auto = false
				this.$router.push({ name: 'mafia009', params: { type: 'third', msg: '소셜 인증 오류 ' + e.error }})
			}
		}
	}
	,watch: {

	}
}
</script>

<style>
.btn-kakao, .btn-apple {
  color: black;
}
</style>